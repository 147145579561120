import { Container, Stack, TextField, Typography, MenuItem } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { TTemplate, getTemplates } from "../utils/network/templates";
import { AuthContext } from "../utils/auth/useAuth";
import { Contract, JsonRpcProvider } from "ethers";
import { NETWORK, RPC_URL_BY_CHAIN_ID } from "../utils/web3";
import { EditMetadata } from "../Skus/components/EditMetadata";
import DeployContract from "../Skus/components/DeployPoM";
import v3PsiMetadataABI from '../utils/web3/v3PsiMetadataABI.json';
import { IOnChainMetadataContract } from "../utils/web3/primaryContractData";


const ManagePoM = () => {
  const { userAuth } = useContext(AuthContext);
  const [, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<TTemplate[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<TTemplate>();
  const [jsonRpcProvider, setJsonRpcProvider] = useState<JsonRpcProvider>();
  const [contractMetadata, setContractMetadata] = useState<IOnChainMetadataContract>({});

  useEffect(() => {
    loadTemplates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadTemplates = async () => {
    if (!userAuth?.token) {
      return;
    }

    try {
      const resp = await getTemplates(userAuth?.token);
      setTemplates(resp.sort((a, b) => {
        return b.id - a.id
      }));
    } catch (e: any) {
      setError(e.message || e);
    }

    setIsLoading(false);
  }

  const handleSelectTemplate = (e: any) => {
    const template = templates?.find(t => t.id === parseInt(e.target.value));
    setSelectedTemplate(template);
    if (!template?.nft_chain_id) {
      setContractMetadata({});
    }
    setJsonRpcProvider(new JsonRpcProvider(RPC_URL_BY_CHAIN_ID[selectedTemplate?.nft_chain_id as NETWORK], selectedTemplate?.nft_chain_id));
    if (selectedTemplate?.secondary_nft_contract_address) {
      setTimeout(loadContractMetadata, 1000);
    }
  }

  const loadContractMetadata = async () =>  {
    if (selectedTemplate?.secondary_nft_contract_address && jsonRpcProvider) {
      setIsLoading(true);

      const contract = new Contract(selectedTemplate?.secondary_nft_contract_address, v3PsiMetadataABI, jsonRpcProvider);

      const nameTx = contract.name().then((tokenName) => {
        setContractMetadata((prev) => ({ ...prev, tokenName }));
      });

      const titleTx = contract.nftTitle().then((nftTitle) => {
        setContractMetadata((prev) => ({ ...prev, nftTitle }));
      });

      const descriptionTx = contract.nftDescription().then((nftDescription) => {
        setContractMetadata((prev) => ({ ...prev, nftDescription }));
      });

      const imageTx = contract.nftImageUri().then((nftImageUri) => {
        setContractMetadata((prev) => ({ ...prev, nftImageUri }));
      });

      const animationTx = contract.nftAnimationUri().then((nftAnimationUri) => {
        setContractMetadata((prev) => ({ ...prev, nftAnimationUri }));
      });

      const totalSupply = contract.totalSupply().then((totalSupply) => {
        setContractMetadata((prev) => ({ ...prev, totalSupply: Number(totalSupply) }));
      });

      await Promise.all([nameTx, titleTx, descriptionTx, imageTx, animationTx, totalSupply]);
      setIsLoading(false);
    }
  }

  const refresh = async () => {
    await loadTemplates();
    setSelectedTemplate(templates.find(t => t.id === selectedTemplate?.id));
    await loadContractMetadata();
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Proof of Meet</Typography>
      <Stack spacing={2} marginY={4}>
        <TextField type="number"
          select
          label="SKU"
          helperText="Select the SKU to use for this Proof of Meet."
          onChange={handleSelectTemplate}
        >
          {templates.map((template) => (
            <MenuItem key={template.id} value={template.id}>
              {template.id} - {template.name}
            </MenuItem>
          ))}
        </TextField>
        {selectedTemplate && <DeployContract isPoM template={selectedTemplate} disabled={isLoading || !selectedTemplate?.nft_chain_id} onChange={refresh} />}
        {selectedTemplate && selectedTemplate.secondary_nft_contract_address && <EditMetadata
          isPoM
          nftAnimationUri={contractMetadata.nftAnimationUri}
          nftDescription={contractMetadata.nftDescription}
          nftImageUri={contractMetadata.nftImageUri}
          nftTitle={contractMetadata.nftTitle}
          onChange={loadContractMetadata} template={selectedTemplate} disabled={isLoading || !selectedTemplate?.nft_chain_id || !selectedTemplate.secondary_nft_contract_address}/>}
      </Stack>
    </Container>
  )
}

export default ManagePoM
