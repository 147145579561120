import { Alert, Button, CircularProgress, Stack, TextField, Link } from "@mui/material";
import { TTemplate } from "../../utils/network/templates";
import { JsonRpcProvider } from "ethers";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TTransaction, TUpdateBaseUri, updateBaseUri } from "../../utils/network/contract";
import { AuthContext } from "../../utils/auth/useAuth";
import { NETWORK, generateTxLink } from "../../utils/web3";

export const EditBaseUri = ({ template, baseUri, onChange, disabled, provider }: { onChange: () => void, template: TTemplate, disabled: boolean, provider?: JsonRpcProvider } & TUpdateBaseUri) => {
  const [isSaving, setIsSaving] = useState(false);

  const { userAuth } = useContext(AuthContext);
  const [txProgress, setTxProgress] = useState<TTransaction>();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<TUpdateBaseUri>({
    shouldUseNativeValidation: true,
    values: {
      baseUri
    }
  });

  const handleSave = async (data: TUpdateBaseUri) => {
    if (!data.baseUri) {
      return;
    }

    setIsSaving(true);

    const tx = await updateBaseUri(template.id, data.baseUri, userAuth?.token || '', template.nft_chain_id as NETWORK);

    setTxProgress(tx);
    await provider?.waitForTransaction(tx.hash) as TTransaction;
    setTxProgress(undefined);

    onChange();
    setIsSaving(false);
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="baseUri"
          defaultValue={baseUri || ''}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              type="url"
              {...register("baseUri", { required: true,
                validate: (v?: string) => !!v?.match(/^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/)
                && !v?.endsWith('/')
              })}
              ref={ref}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              label="NFT Data URI"
              autoComplete="off"
              placeholder="https://ipfs.legitimate.tech/ipfs/Qma1RRurxrXydbQZRYGeE6EvdD7Y1W4TDDhMYCMXU4sXhp"
              helperText="This is the HTTP(s) base URI for the folder storing the NFT data files 1, 2, 3... without the trailing slash. (required)"
              disabled={disabled}
              error={!!errors.baseUri}
            />
          )}
        />
        {txProgress && <Alert severity="info">
          Update transactions in progress...<br />
          <Link href={generateTxLink(template.nft_chain_id as NETWORK, txProgress.hash)} target="_blank">{txProgress.hash}</Link>
        </Alert>}
        {(baseUri) && <Alert severity="success">
          Product NFT data URI saved
        </Alert>}
        {!template?.nft_contract_address && <Alert severity="info">
          Digital Product Passport must be deployed before Product NFT data URI can be edited
        </Alert>}
        <Button type="submit" variant="contained" disabled={!isDirty || isSaving || disabled || !isValid}
          startIcon={isSaving && <CircularProgress size={16} />}
        >Save NFT Data URI</Button>
      </Stack>
    </form>
  )
}
