import {BASE_URL} from './baseUrl';
import {NetworkRequestError} from './error';

export interface TLabel {
  name: string;
}

export interface TTag {
  updated_at: number;
  created_at: number;
  uuid: string;
  name?: string;
  notes?: string;
  template_id?: number | null;
  shareable: boolean;
  nft_chain_id?: number;
  nft_contract_address?: string;
  nft_token_id?: number;
  verifications: number;
  qc: boolean;
  tamper_enabled?: boolean;
  latest_counter?: number;
  latest_tamper?: string;
}

export type TUpdateParams = Partial<
  Pick<
    TTag,
    | 'notes'
    | 'template_id'
    | 'nft_chain_id'
    | 'nft_contract_address'
    | 'nft_token_id'
  >
>;

export interface TPaginationInfo {
  currentPage: number;
  pageItems: number;
  totalPages: number;
  totalCount: number;
}

export const getTags = async (
  authToken: string,
  page: number = 1,
  template_id?: number,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const urlSearchParams = new URLSearchParams({
      page: page.toString(),
      page_size: '250',
    });
    if (template_id) {
      urlSearchParams.append('template_id', template_id.toString());
    }
    const response = await fetch(
      `${serverBaseUrl}/tags?${urlSearchParams.toString()}`,
      {
        method: 'GET',
        headers: {
          Authorization: authToken,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    );
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    //Pagination Info
    const currentPage = Number(response.headers.get('current-page'));
    const pageItems = Number(response.headers.get('page-items'));
    const totalPages = Number(response.headers.get('total-pages'));
    const totalCount = Number(response.headers.get('total-count'));
    const paginationInfo = {
      currentPage,
      pageItems,
      totalPages,
      totalCount,
    } as TPaginationInfo;
    const responseBody: TTag[] = await response.json();
    return {
      tags: responseBody,
      pagination: paginationInfo,
    }
  } catch (e) {
    throw e;
  }
};

export const createTag = async (
  authToken: string,
  tagId: string,
  advancedOptions?: {
    name?: string;
    tagTemplateId?: number;
    chainId?: string;
    contractAddress?: string;
    tokenId?: string;
  },
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  const tag = advancedOptions
    ? {
        uuid: tagId,
        name: advancedOptions.name,
        template_id: advancedOptions.tagTemplateId,
        nft_chain_id: advancedOptions.chainId
          ? parseInt(advancedOptions.chainId, 10)
          : undefined,
        nft_contract_address: advancedOptions.contractAddress,
        nft_token_id: advancedOptions.tokenId
          ? parseInt(advancedOptions.tokenId, 10)
          : undefined,
      }
    : {
        uuid: tagId,
      };

  try {
    const response = await fetch(`${serverBaseUrl}/tags`, {
      method: 'POST',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tag,
      }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const createNFTFromTag = async (
  tagId: string,
  contractAddress?: string,
  chainId?: string,
  mintAddress?: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    // Add body for request
    const response = await fetch(
      `${serverBaseUrl}/tags/${tagId}/nft`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          contractAddress,
          chainId,
          mintAddress,
        }),
      },
    );

    // check reponse
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const fetchTag = async (
  authToken: string,
  tagId: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/${tagId}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const updateTag = async (
  authToken: string,
  tagId: string,
  params: TUpdateParams,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const response = await fetch(`${serverBaseUrl}/tags/${tagId}`, {
      method: 'PUT',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tag: params,
      }),
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: TTag = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};
