import { Button, Container, IconButton, Link, Typography } from "@mui/material"
import { useContext } from "react"
import { TTemplate, getTemplates, deleteTemplate } from "../utils/network/templates";
import { AuthContext } from "../utils/auth/useAuth";
import { DataGrid, GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID, generateAddressLink } from "../utils/web3";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useQuery } from "@tanstack/react-query";
import { OfflineShare } from "@mui/icons-material";

const SkuIndex = () => {
  const { userAuth } = useContext(AuthContext);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['SKUs'],
    queryFn: () => getTemplates(userAuth?.token || ''),
  })

  const columns = [
    {
      field: 'id',
      headerName: 'SKU ID',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/skus/${params.value}`}
          startIcon={<EditIcon />}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      type: 'actions',
      width: 40,
      getActions: (params: GridValueGetterParams<TTemplate>) => {
        return [
          <IconButton disabled={!params.row.analytics_url} color="primary" href={params.row.analytics_url || ''} target="_blank">
            <LeaderboardIcon/>
          </IconButton>
        ]
      },
      field: 'analytics'
    },
    {
      field: 'tag_verifications_count',
      headerName: 'Verifications',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/views?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'tags_count',
      headerName: 'Tags',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        return <Button href={`/tags?sku=${params.row.id}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'quality_checked_tags_count',
      headerName: 'QC',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTemplate, number>) => {
        if (params.row.tags_count === params.row.quality_checked_tags_count) {
          return <CheckCircleIcon color="success" />
        }
        return `${params.row.quality_checked_tags_count}/${params.row.tags_count}`
      }
    },
    {
      type: 'actions',
      width: 40,
      getActions: (params: GridValueGetterParams<TTemplate>) => {
        return [
          <IconButton disabled={params.row.tags_count > 0} color="error" onClick={() => handleDeleteTemplate(params.row.id)}>
            <DeleteOutlineIcon />
          </IconButton>
        ]
      },
      field: 'delete'
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 180,
      type: 'dateTime',
      valueGetter: (params: any) => {
        return new Date(params.row.created_at);
      }
    },
    {
      field: 'name',
      headerName: 'Item Name',
      width: 250,
      type: 'string',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 250,
      type: 'string',
    },
    {
      field: 'nft_chain_id',
      headerName: 'Chain',
      width: 140,
      type: 'string',
      valueGetter: (params: GridValueGetterParams<TTemplate, NETWORK>) => {
        if (!params.row.nft_chain_id) {
          return '';
        } else if (!Object.values(NETWORK).includes(params.row.nft_chain_id)) {
          return 'Unknown';
        }
        return NETWORK_NAME_BY_CHAIN_ID[params.row.nft_chain_id as NETWORK];
      },
    },
    {
      field:'nft_contract_address',
      headerName: 'Contract Address',
      width: 360,
      type: 'string',
      renderCell: (params: GridRenderCellParams<TTemplate, string>) => {
        if (!params.value) {
          return null;
        } else if (!params.row.nft_chain_id || !Object.values(NETWORK).includes(params.row.nft_chain_id)) {
          return params.value;
        }
        return <Link href={generateAddressLink(params.row.nft_chain_id as NETWORK, params.value)} target="_blank">
          {params.value}
        </Link>
      },
    },
    {
      field:'secondary_nft_contract_address',
      headerName: 'POM',
      width: 40,
      type: 'string',
      renderCell: (params: GridRenderCellParams<TTemplate, string>) => {
        if (!params.value) {
          return null;
        }
        return (
          <IconButton href={generateAddressLink(params.row.nft_chain_id as NETWORK, params.value)} target="_blank">
            <OfflineShare />
          </IconButton>
        )
      },
    },
  ]

  const handleDeleteTemplate = async (templateId: number) => {
    if (!userAuth?.token) {
      return;
    }

    try {
      await deleteTemplate(userAuth?.token, templateId);
      refetch();
    } catch (e: any) {
      console.error(e);
    }
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">SKUs</Typography>
      <Button href="/skus/new" variant="contained" sx={{ my: 2 }}
        startIcon={<AddIcon />}
      >
        New SKU
      </Button>
      <DataGrid
        rows={data || []}
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'id', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[25, 50, 100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default SkuIndex
