import { Alert, Button, CircularProgress, Stack, TextField, Link } from "@mui/material";
import { TTemplate } from "../../utils/network/templates";
import { JsonRpcProvider } from "ethers";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { TTransaction, TUpdateNftMetadata, updateMetadata } from "../../utils/network/contract";
import { AuthContext } from "../../utils/auth/useAuth";
import { NETWORK, generateTxLink } from "../../utils/web3";
import { Video } from "../../components/Video";
import UploadAdornment from "./UploadAdornment";

export const EditMetadata = ({ template, nftTitle, nftDescription, nftImageUri, nftAnimationUri, onChange, disabled, provider, isPoM }: { onChange: () => void, template: TTemplate, disabled: boolean, provider?: JsonRpcProvider, isPoM?: boolean } & TUpdateNftMetadata) => {
  const [isSaving, setIsSaving] = useState(false);
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement|null>(null);

  const { userAuth } = useContext(AuthContext);
  const [txProgress, setTxProgress] = useState<{ completed: TTransaction[], inProgress: TTransaction[]}>({
    completed: [],
    inProgress: []
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    setValue,
    getValues,
    control,
  } = useForm<TUpdateNftMetadata>({
    values: {
      nftTitle,
      nftDescription,
      nftImageUri,
      nftAnimationUri
    }
  });

  const handleSave = async (data: TUpdateNftMetadata) => {
    setIsSaving(true);

    const nftMetadataUpdate = {} as TUpdateNftMetadata;

    if (data.nftTitle && data.nftTitle !== nftTitle) {
      nftMetadataUpdate['nftTitle'] = data.nftTitle;
    }
    if (data.nftDescription && data.nftDescription !== nftDescription) {
      nftMetadataUpdate['nftDescription'] = data.nftDescription;
    }
    if (data.nftImageUri && data.nftImageUri !== nftImageUri) {
      nftMetadataUpdate['nftImageUri'] = data.nftImageUri;
    }
    if (data.nftAnimationUri && data.nftAnimationUri !== nftAnimationUri) {
      nftMetadataUpdate['nftAnimationUri'] = data.nftAnimationUri;
    }

    const txList = await updateMetadata(template.id, nftMetadataUpdate, userAuth?.token || '', template.nft_chain_id as NETWORK, isPoM);

    await Promise.all(txList.map(async (tx) => {
      setTxProgress((prev) => ({
        inProgress: [...prev.inProgress, tx],
        completed: prev.completed
       }));
      await provider?.waitForTransaction(tx.hash) as TTransaction;
      setTxProgress((prev) => ({
        inProgress: prev.inProgress.filter((inProgressTx) => tx.hash !== inProgressTx.hash),
        completed: [...prev.completed, tx]
       }));
    }));

    onChange();
    setIsSaving(false);
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack spacing={2}>
        <Controller
          control={control}
          name="nftTitle"
          defaultValue={nftTitle || ''}
          render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            {...register("nftTitle")}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label="Product Title"
            autoComplete="off"
            placeholder="0xAvenue PHYSICALS v1"
            helperText="This is the name of the product. Max 50 characters. (required)"
            inputProps={{ maxLength: 50 }}
            disabled={disabled}
          />)}
        />
        <Controller
          control={control}
          name="nftDescription"
          defaultValue={nftTitle || ''}
          render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            {...register("nftDescription")}
            label="Product Description"
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            value={value}
            autoComplete="off"
            multiline
            placeholder="0xAvenue ON-CHAIN PHYSICALS V1 is the first of 0xAvenue’s On-Chain Physicals collection designed exclusively for the trailblazers of the web3 revolution. Immerse yourself in a world of opulence with this extraordinary 300 gsm T-shirt, meticulously crafted from a refined blend of 79% cotton and 21% polyester complemented by a perfect balance of breathability and durability, ensuring it stands the test of time."
            helperText="This is the description of the product. Markdown formatting is supported, suggested maximum 2-3 sentences. (required)"
            disabled={disabled}
            defaultValue={nftDescription}
          />)}
        />
        {getValues('nftImageUri') && <img onLoad={(e) => { setLoadedImage(e.currentTarget) }} src={getValues('nftImageUri') || ''} alt={nftTitle} style={{ maxWidth: '400px', border: '1px solid black' }}/>}
        <Controller
          control={control}
          name="nftImageUri"
          defaultValue={nftTitle || ''}
          render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            {...register("nftImageUri")}
            value={value}
            label="Product Image URL"
            type="url"
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            autoComplete="off"
            placeholder="https://ipfs.legitimate.tech/ipfs/QmeThpeL12ycHb2k5V45BSusQQPd2WhTXpBscQ8nu6LV9p"
            helperText="Image for the product in PNG or JPEG format, square image recommended. Maximum 10,000 pixels in either dimension. (required)"
            disabled={disabled}
            defaultValue={nftImageUri}
            InputProps={{
              endAdornment:
                <UploadAdornment
                  accept="image/jpeg, image/png, .jpeg, .jpg, .png"
                  url={getValues('nftImageUri') || ''}
                  onUpload={(url) => setValue('nftImageUri', url, { shouldDirty: true, shouldValidate: true, shouldTouch: true })}
                />
            }}
          />)}
        />
        {((loadedImage?.naturalHeight && loadedImage?.naturalHeight >= 10000) ||
          (loadedImage?.naturalWidth && loadedImage?.naturalWidth >= 10000)) && <Alert severity="warning">
          The image resolution is greater than 10,000 pixels in either dimension. This may cause rendering and optimization issues on some devices. Please consider resizing the image to a smaller resolution.
        </Alert>}
        {getValues('nftAnimationUri') && <Video src={getValues('nftAnimationUri') || ''} style={{maxWidth: '400px', border: '1px solid black' }} />}
        <Controller
          control={control}
          name="nftAnimationUri"
          defaultValue={nftTitle || ''}
          render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            {...register("nftAnimationUri")}
            type="url"
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            value={value}
            label="Product Animation URL"
            autoComplete="off"
            placeholder="https://ipfs.legitimate.tech/ipfs/QmZXHCJJjZHfjVZbudmix8v1oLX7J3ARzSATAwcQeLp8wk"
            helperText="Animation for the product in H.264 MP4 format. (optional)"
            disabled={disabled}
            defaultValue={nftAnimationUri}
            InputProps={{
              endAdornment:
                <UploadAdornment
                  accept="video/mp4, .mp4"
                  url={getValues('nftAnimationUri') || ''}
                  onUpload={(url) => setValue('nftAnimationUri', url, { shouldDirty: true, shouldValidate: true, shouldTouch: true })}
                />
            }}
          />)}
        />
        {txProgress.inProgress.length > 0 && <Alert severity="info">
          Update transactions in progress...<br />
          {txProgress.inProgress.map((tx) => <><Link href={generateTxLink(template.nft_chain_id as NETWORK, tx.hash)} target="_blank">{tx.hash}</Link><br /></>)}
        </Alert>}
        {txProgress.inProgress.length === 0 && (nftTitle || nftDescription || nftImageUri) && <Alert severity="success">
          Product data saved: {nftTitle && 'title '} {nftDescription && 'description '} {nftImageUri && 'image '} {nftAnimationUri && 'animation'}
        </Alert>}
        {!template?.nft_contract_address && <Alert severity="info">
          Digital Product Passport must be deployed before Digital Product ID can be configured
        </Alert>}
        <Button type="submit" variant="contained" disabled={!isDirty || isSaving || disabled}
          startIcon={isSaving && <CircularProgress size={16} />}
        >Save Product Information</Button>
      </Stack>
    </form>
  )
}
