import React, { useContext } from 'react';
import { AppBar, Box, Toolbar, Typography, Menu, Container, Button, MenuItem, Link } from '@mui/material';
import { ReactComponent as LogoSymbol } from './assets/LogoSymbol.svg'
import { AuthContext } from './utils/auth/useAuth';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { roleLabels } from './utils/auth/roles';
import { baseUrlLabels, BASE_URL } from './utils/network/baseUrl';

const pages = [{
  title: 'SKUs',
  href: '/skus'
}, {
  title: 'Tags',
  href: '/tags'
}, {
  title: 'Verifications',
  href: '/views'
}, {
  title: 'Gas',
  href: '/gas'
}];

const admin = [
{
  title: 'PoM',
  href: '/pom'
}, {
  title: 'Users',
  href: '/users'
}];

function ResponsiveAppBar() {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenAdminMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAdmin(event.currentTarget);
  };

  const handleCloseAdminMenu = () => {
    setAnchorElAdmin(null);
  };

  const handleLogOut = () => {
    handleCloseUserMenu();
    logout();
    navigate('/');
  }

  const { userAuth, logout } = useContext(AuthContext)

  return (
    <>
      <AppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Link href="https://legitimate.tech" target='_blank' sx={{ lineHeight: 0}} key="logo">
              <LogoSymbol style={{ fill: 'currentColor', width: '32px', marginRight: '24px' }} />
            </Link>

            {userAuth && <Box sx={{ flexGrow: 1, display: 'flex' }}>
              {pages.map((page) => (
                <Link href={page.href} key={page.href}>
                  <Button
                    key={page.title}
                    sx={{ my: 2, color: 'white', display: 'block',
                      textDecoration: pathname.indexOf(page.href) ===0 ? 'underline' : 'none',
                      borderRadius: 0,
                    }}
                    href={page.href}
                  >
                    {page.title}
                  </Button>
                </Link>
              ))}
              {userAuth.data.user.role === 'admin' && <Link href="#" key="admin">
                  <Button
                    sx={{
                      my: 2, color: 'white', display: 'block',
                      borderRadius: 0,
                    }}
                    onClick={handleOpenAdminMenu}
                  >
                    Admin
                  </Button>
                </Link>}
              {userAuth.data.user.role === 'admin' && <Menu
                key="adminMenu"
                sx={{ mt: '45px' }}
                id="menu-admin"
                anchorEl={anchorElAdmin}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                open={Boolean(anchorElAdmin)}
                onClose={handleCloseAdminMenu}
              >

                {admin.map((page) => <Link href={page.href}>
                  <MenuItem key={page.title + page.href}>
                    <Typography>{page.title}</Typography>
                  </MenuItem>
                </Link>)}
              </Menu>}
            </Box>}

            {userAuth && <Box sx={{ flexGrow: 0 }}>
              <Button key="userButton" onClick={handleOpenUserMenu} sx={{ my: 2, color: 'white', display: 'block', textTransform: 'none' }}
              >
                <Typography>{userAuth?.data?.user?.email}</Typography>
              </Button>
              <Menu
                key="userMenu"
                sx={{ mt: '45px' }}
                id="menu-user"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >

                <Link href="/profile" sx={{ textDecoration: 'none' }}>
                  <MenuItem key="edit">
                    <Typography>Edit Profile</Typography>
                  </MenuItem>
                </Link>
                <MenuItem key="baseUrl" disabled>
                  <Typography variant='body2'>{baseUrlLabels[window.localStorage.getItem('baseUrl') as BASE_URL]}</Typography>
                </MenuItem>
                <MenuItem key="role" disabled>
                  <Typography variant='body2'>{roleLabels[userAuth?.data?.user?.role]}</Typography>
                </MenuItem>
                <MenuItem key="logout" onClick={handleLogOut}>
                  <Typography>Log Out</Typography>
                </MenuItem>
              </Menu>
            </Box>}
          </Toolbar>
        </Container>
      </AppBar>
      <Outlet />
    </>
  );
}
export default ResponsiveAppBar;
