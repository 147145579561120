import { Button, Container, Stack, TextField, Alert, Typography, LinearProgress, MenuItem, FormControlLabel, Checkbox, CircularProgress, Accordion, AccordionSummary, AccordionDetails, Link } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { TTemplate, getTemplate, updateTemplate } from "../utils/network/templates";
import { AuthContext } from "../utils/auth/useAuth";
import { useParams } from 'react-router-dom';
import { isTestnet, NETWORK, NETWORK_NAME_BY_CHAIN_ID, RPC_URL_BY_CHAIN_ID } from "../utils/web3";
import DeployContract from "./components/DeployContract";
import { JsonRpcProvider } from "ethers";
import { EditMetadata } from "./components/EditMetadata";
import { useForm } from "react-hook-form";
import MintNfts from "./components/MintNFTs";
import ActivateTags from "./components/ActivateTags";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPrimaryContractData } from "../utils/web3/primaryContractData";
import { LGTContractVersion } from "../utils/network/contract";
import GasPriceIndicator from "../components/GasPriceIndicator";
import { EditBaseUri } from "./components/EditBaseUri";
import UploadAdornment from "./components/UploadAdornment";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const ViewSku = () => {
  const { userAuth } = useContext(AuthContext);
  const [jsonRpcProvider, setJsonRpcProvider] = useState<JsonRpcProvider>();

  const { skuId } = useParams();

  const { data: template, refetch: refetchTemplate, isLoading: isTemplateLoading, isRefetching } = useQuery({
    queryKey: ['getTemplate', skuId],
    queryFn: async () => {
      if (!userAuth?.token || !skuId) {
        return;
      }

      return await getTemplate(userAuth?.token, Number(skuId));
    },
    enabled: !!userAuth?.token && !!skuId
  })

  const { data: contractMetadata, refetch: refetchContractMetadata } = useQuery({
    queryKey: ['getContractMetadata', template?.nft_contract_address, template?.nft_chain_id, template?.contract_version],
    queryFn: async () => {
      if (template?.nft_contract_address && jsonRpcProvider && template?.contract_version) {
        return await fetchPrimaryContractData({
          contractAddress: template?.nft_contract_address,
          provider: jsonRpcProvider,
          contractVersion: template?.contract_version
        })
      }
    },
    enabled: !!template?.nft_contract_address && !!jsonRpcProvider && !!template?.contract_version && !!template?.nft_chain_id
  })

  const { mutate: mutateTemplate, isPending: isUpdatingTemplate, error: mutateTemplateError } = useMutation({
    mutationFn: async (data: TTemplate) => {
      return await updateTemplate(userAuth?.token || '', Number(skuId), data);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty },
    getValues, setValue,
  } = useForm<TTemplate>({
    shouldUseNativeValidation: true,
    values: template
  });

  useEffect(() => {
    if (template?.nft_chain_id) {
      setJsonRpcProvider(new JsonRpcProvider(RPC_URL_BY_CHAIN_ID[template?.nft_chain_id as NETWORK], template?.nft_chain_id));
    }
  }, [template?.nft_chain_id])

  const handleSaveSku = async (data: TTemplate) => {
    mutateTemplate(data, {
      onSuccess: () => {
        refetchTemplate();
      }
    });
  }

  const isRedirectEnabled = !!(template?.redirect_url && template?.redirect_url.length > 0);

  if (!template) {
    return <Container maxWidth="xl">
      <Typography variant="h2">SKU: {skuId}</Typography>
      <LinearProgress />
    </Container>
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">SKU: {skuId}</Typography>
      <Stack direction={{ md: 'row', sm: 'column' }} spacing={4}>
        <Stack>
          <form onSubmit={handleSubmit(handleSaveSku)}>
            <Stack spacing={2} marginY={4}>
              <Typography variant="h4">1. Edit SKU Information</Typography>
              <TextField type="number"
                {...register("id", { required: true })}
                disabled
                name="id"
                defaultValue={template?.id}
                id="id"
                required
                label="SKU ID"
                helperText="This SKU ID is used for the manufacturing and programming of the Legitimate Tags. This is set by Legitimate and cannot be changed."
              />
              <TextField type="text"
                {...register("name", { required: true })}
                required
                label="Item Name"
                defaultValue={template?.name}
                autoComplete="off"
                placeholder="0xAvenue PHYSICALS v1"
                helperText="This is the display name of the item and can also be used for the title of associated Digital Product ID. Max 50 characters."
                inputProps={{ maxLength: 50 }}
              />
              <TextField type="text"
                {...register("notes")}
                label="Notes"
                defaultValue={template?.notes}
                multiline
                placeholder="Season 1 of the 0xAvenue PHYSICALS collection, exclusive to friends and family."
                helperText="Use this to add any additional notes about this item. This will not be displayed publicly."
              />

              {getValues('header_logo_url') && <img src={getValues('header_logo_url') || ''} style={{ maxWidth: '200px', width: "fit-content", height: '36px', border: '1px solid black' }} alt="logo" />}
              <TextField
                {...register("header_logo_url")}
                label="Header Logo URL"
                type="url"
                autoComplete="off"
                placeholder="https://ipfs.legitimate.tech/ipfs/QmeThpeL12ycHb2k5V45BSusQQPd2WhTXpBscQ8nu6LV9p"
                helperText="Display brand or partner logo in the header and replace the default Legitimate logo. (optional)"
                defaultValue={template.header_logo_url}
                InputProps={{
                  endAdornment:
                    <UploadAdornment
                      accept="image/jpeg, image/png, image/svg+xml, .jpeg, .jpg, .png, .svg"
                      url={getValues('header_logo_url') || ''}
                      onUpload={(url) => setValue('header_logo_url', url, { shouldDirty: true, shouldValidate: true, shouldTouch: true })}
                    />
                }}
              />
              <TextField
                {...register("nft_chain_id")}
                label="Blockchain for Digital Product Passport"
                select
                defaultValue={template?.nft_chain_id}
                disabled={!!template?.nft_contract_address}
                helperText="This is the blockchain for the Digital Product Passport and cannot be changed after the contract is deployed."
              >
                <MenuItem value={NETWORK.AVALANCHE}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.AVALANCHE]} (Fast and Recommended)
                </MenuItem>
                <MenuItem value={NETWORK.POLYGON}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.POLYGON]} (Slower, Wider Compatibility)
                </MenuItem>
                <MenuItem value={NETWORK.BASE}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.BASE]} (Supported by Coinbase)
                </MenuItem>
                <MenuItem value={NETWORK.LAMINA1}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.LAMINA1]}
                </MenuItem>
                <MenuItem value={NETWORK.ETHEREUM} disabled>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.ETHEREUM]} (ETH Deposit Required)
                </MenuItem>
                <MenuItem value={NETWORK.ETHEREUM_SEPOLIA}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.ETHEREUM_SEPOLIA]} (Sepolia)
                </MenuItem>
                <MenuItem value={NETWORK.AVALANCHE_FUJI}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.AVALANCHE_FUJI]} (Fuji)
                </MenuItem>
                <MenuItem value={NETWORK.POLYGON_AMOY}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.POLYGON_AMOY]} (Amoy)
                </MenuItem>
                <MenuItem value={NETWORK.BASE_SEPOLIA}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.BASE_SEPOLIA]} (Sepolia)
                </MenuItem>
                <MenuItem value={NETWORK.LAMINA1_FUJI}>
                  {NETWORK_NAME_BY_CHAIN_ID[NETWORK.LAMINA1_FUJI]} (Fuji)
                </MenuItem>
              </TextField>
              {isTestnet(template?.nft_chain_id as NETWORK) && <Alert severity="warning">
                Testnet chains are for testing purposes only. Data on testnet chains are not permanent and may be lost in the future.
              </Alert>}

              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>Advanced Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={2}>
                    <TextField
                      {...register('builder_io_url')}
                      label='Builder.io Path'
                      defaultValue={template.builder_io_url}
                      autoComplete="off"
                      placeholder="/b/XXXXXXXXXXXXXXXX/demo-page"
                      helperText="This is the builder.io path for the embedded content. (optional)"
                    />
                    <TextField
                      {...register('builder_io_api_key')}
                      label='Builder.io API Key'
                      defaultValue={template.builder_io_url}
                      autoComplete="off"
                      placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                      helperText="The API key can be found in the Environments section of the Settings page. (optional)"
                    />
                    {!getValues('builder_io_url') === !!getValues('builder_io_api_key') && <Alert severity="error">
                      You must enter both the Builder.io URL and API Key to enable the embedded content.
                    </Alert>}
                    {getValues('builder_io_url') && getValues('builder_io_api_key') && <Alert severity="info">
                      <strong>The preview only shows the embedded builder.io content and does not show actual item information or customizations like the header image.</strong>
                      <br />
                      <br />
                      <Link href={
                        `https://verify.legitimate.tech${getValues('builder_io_url')}`
                      } target="_blank" rel="noreferrer">{`https://verify.legitimate.tech${getValues('builder_io_url')}`}</Link>
                      <br />
                      <br />
                      Please make sure you published the content in Builder.io before opening the link above. If the content
                      renders here but does not display on the tag, please double check your URL and API key.
                    </Alert>}
                    <TextField type="text"
                      {...register("redirect_url")}
                      label="Redirect URL"
                      defaultValue=''
                      placeholder="https://www.example.com/"
                      helperText="Redirect tag to a different URL with tag parameters and bypass Legitimate's login and hosted content. (optional)"
                    />
                    {getValues('redirect_url') && <Alert severity="warning">
                      <strong>When a redirect URL is set, all content and functionalities hosted by Legitimate including login will be hidden.</strong><br/><br />
                      The blockchain-based Digital Product Passport IDs and tag view information can still be accessed via our APIs.<br/>
                      Please refer to our API documentation for more details around creating custom experiences.
                      <pre>
                        {(template?.redirect_url || 'https://your.url.com/') + '?uid=XXXXXXXXXXXXXX&ctr=YYYYYY&cmac=ZZZZZZZZZZZZZZZZ'}
                      </pre>
                      uid - unique identification code of the Legitimate Tag (14 hex characters)<br/>
                      ctr - counter for the number of times the tag is read (6 hex characters)<br/>
                      cmac - AES CMAC signature for verifying the validity of the tag and read counter (16 hex characters)<br /><br />
                    </Alert>}
                    <TextField
                      {...register('web3_authentication_enabled')}
                      label='Account Login Options'
                      select
                      defaultValue={template.web3_authentication_enabled}
                      disabled={isRedirectEnabled}
                    >
                      <MenuItem value={1}>Email, SMS and Social (Default)</MenuItem>
                      <MenuItem value={2}>Email, SMS, Social, and Wallet (Web3 Enabled)</MenuItem>
                      <MenuItem value={0}>No Login (Disable Content Gating)</MenuItem>
                    </TextField>
                    <TextField type="number"
                      {...register("contract_version")}
                      select
                      label="Digital Product Passport Type"
                      disabled={!!template?.nft_contract_address}
                      defaultValue={template?.contract_version}
                      helperText="Legitimate stores product data on the blockchain by default. Select self-managed to host your own product data via a configurable URI."
                    >
                      <MenuItem value={LGTContractVersion.V3_PSI_ON_CHAIN_METADATA}>
                        LGTv3 On-Chain Metadata Smart Contract (Recommended)
                      </MenuItem>
                      <MenuItem value={LGTContractVersion.V3_PSI_BASE_URI}>
                        LGTv3 Self-Managed URI Metadata Smart Contract (Advanced)
                      </MenuItem>
                      <MenuItem value={LGTContractVersion.V3_BASE_URI} disabled>
                        LGTv3 Legacy Self-Managed Metadata Smart Contract (Deprecated)
                      </MenuItem>
                      <MenuItem value={LGTContractVersion.LEGACY_UNSUPPORTED} disabled>
                        Custom Smart Contract (Unsupported)
                      </MenuItem>
                    </TextField>
                    <FormControlLabel disabled={!!template?.nft_contract_address} control={
                      <Checkbox {...register('auto_manage_tag_sequence')} defaultChecked={template?.auto_manage_tag_sequence} />
                    } label="Automatically assign digital product IDs to Legitimate Tags sequentially" />
                  </Stack>
                </AccordionDetails>
              </Accordion>

              {mutateTemplateError && <Alert severity='error'>{mutateTemplateError.message}</Alert>}
              <Button type="submit" variant="contained" disabled={!isDirty || isTemplateLoading || isUpdatingTemplate || isRefetching}
                startIcon={(isUpdatingTemplate) && <CircularProgress size={16} />}
              >
                Save
              </Button>
            </Stack>
          </form>

          <Stack spacing={2} marginY={4}>
            <Typography variant="h4">2. Deploy Digital Product Passport</Typography>
            <DeployContract template={template} disabled={!!template.nft_contract_address}
              provider={jsonRpcProvider} onChange={refetchTemplate} tokenName={contractMetadata?.tokenName} tokenSymbol={contractMetadata?.symbol}
            />
          </Stack>

          <Stack spacing={2} marginY={4}>
            <Typography variant="h4">3. Configure Digital Product ID</Typography>
            {template.contract_version === LGTContractVersion.V3_PSI_ON_CHAIN_METADATA && <EditMetadata template={template}
              nftTitle={contractMetadata?.nftTitle}
              nftDescription={contractMetadata?.nftDescription}
              nftImageUri={contractMetadata?.nftImageUri}
              nftAnimationUri={contractMetadata?.nftAnimationUri}
              onChange={refetchContractMetadata}
              disabled={!template.nft_contract_address || isTemplateLoading}
              provider={jsonRpcProvider}
            />}
            {(template.contract_version === LGTContractVersion.V3_BASE_URI || template.contract_version === LGTContractVersion.V3_PSI_BASE_URI) &&
              <EditBaseUri template={template} baseUri={contractMetadata?.baseURI} onChange={refetchContractMetadata} disabled={!template.nft_contract_address || isTemplateLoading || isRedirectEnabled} provider={jsonRpcProvider} />
            }
            {template.contract_version === LGTContractVersion.LEGACY_UNSUPPORTED && <Alert severity="info">
              This contract is custom or deprecated and does not have support editing.
            </Alert>}
            {!template.contract_version && <Alert severity="info">
              Please select a Digital Product Passport type before configuring product data.
            </Alert>}
          </Stack>
          <MintNfts disabled={template?.tags_count === 0 || isTemplateLoading} template={template} onChange={refetchContractMetadata} contractMetadata={contractMetadata} provider={jsonRpcProvider}/>
          <ActivateTags template={template} totalSupply={contractMetadata?.totalSupply || 0} />
        </Stack>
        <Stack spacing={2} marginY={4}>

        </Stack>
      </Stack>
      <GasPriceIndicator chainId={template?.nft_chain_id as NETWORK} />
    </Container>
  )
}

export default ViewSku
