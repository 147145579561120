import { NETWORK, NFT_SERVER_BASE_URL_BY_CHAIN_ID } from "../web3";
import { BASE_URL } from "./baseUrl";

export interface TTransaction{
  hash: string;
}

export enum LGTContractVersion {
  LEGACY_UNSUPPORTED = 0,
  V3_BASE_URI = 1,
  V3_PSI_BASE_URI = 2,
  V3_PSI_ON_CHAIN_METADATA = 3,
  ERC721_NFT_DISTRIBUTION = 4,
  ERC1155_NFT_DISTRIBUTION = 5,
}

export const deployContract = async (
  templateId: number,
  tokenName: string,
  tokenSymbol: string,
  authToken: string,
  chainId: NETWORK,
  contractVersion: LGTContractVersion = LGTContractVersion.V3_PSI_ON_CHAIN_METADATA,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/deploy`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        tokenName,
        tokenSymbol,
        authToken,
        contractVersion,
        baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
      }),
    });
    const responseBody: TTransaction = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export interface TUpdateNftMetadata {
  nftTitle?: string;
  nftDescription?: string;
  nftImageUri?: string;
  nftAnimationUri?: string;
}

export interface TUpdateBaseUri {
  baseUri?: string;
}

export const updateMetadata = async (
  templateId: number,
  metadata: TUpdateNftMetadata,
  authToken: string,
  chainId: NETWORK,
  isSecondary?: boolean,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/metadata`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        metadata,
        authToken,
        isSecondary,
        baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
      }),
    });
    const responseBody: TTransaction[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const mintNfts = async (
  templateId: number,
  authToken: string,
  quantity: number,
  chainId: NETWORK,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/mint`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        quantity,
        authToken,
        baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
      }),
    });
    const responseBody: TTransaction[] = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

export const updateBaseUri = async (
  templateId: number,
  uri: string,
  authToken: string,
  chainId: NETWORK,
) => {
  try {
    const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/uri`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        uri,
        authToken,
        baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
      }),
    });
    const responseBody: TTransaction = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};

// export const updateTitle = async (
//   templateId: number,
//   title: string,
//   authToken: string,
//   chainId: NETWORK,
// ) => {
//   try {
//     const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/title`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         templateId,
//         title,
//         authToken,
//         baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
//       }),
//     });
//     const responseBody: TTransaction = await response.json();
//     return responseBody;
//   } catch (e) {
//     throw e;
//   }
// };

// export const updateDescription = async (
//   templateId: number,
//   description: string,
//   authToken: string,
//   chainId: NETWORK,
// ) => {
//   try {
//     const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/description`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         templateId,
//         description,
//         authToken,
//         baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
//       }),
//     });
//     const responseBody: TTransaction = await response.json();
//     return responseBody;
//   } catch (e) {
//     throw e;
//   }
// };

// export const updateImageUri = async (
//   templateId: number,
//   imageUri: string,
//   authToken: string,
//   chainId: NETWORK,
// ) => {
//   try {
//     const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/imageUri`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         templateId,
//         imageUri,
//         authToken,
//         baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
//       }),
//     });
//     const responseBody: TTransaction = await response.json();
//     return responseBody;
//   } catch (e) {
//     throw e;
//   }
// };

// export const updateAnimationUri = async (
//   templateId: number,
//   animationUri: string,
//   authToken: string,
//   chainId: NETWORK,
// ) => {
//   try {
//     const response = await fetch(`${NFT_SERVER_BASE_URL_BY_CHAIN_ID[chainId]}/dashboard/contract/animationUri`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         templateId,
//         animationUri,
//         authToken,
//         baseUrl: localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
//       }),
//     });
//     const responseBody: TTransaction = await response.json();
//     return responseBody;
//   } catch (e) {
//     throw e;
//   }
// };
