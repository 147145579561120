import { Alert, Button, CircularProgress, Link, MenuItem, Stack, TextField } from "@mui/material";
import { TTemplate } from "../../utils/network/templates";
import { useForm } from "react-hook-form";
import { useContext, useState } from "react";
import { NETWORK, NETWORK_NAME_BY_CHAIN_ID, generateAddressLink, generateTxLink } from "../../utils/web3";
import { LGTContractVersion, deployContract } from "../../utils/network/contract";
import { AuthContext } from "../../utils/auth/useAuth";
import { JsonRpcProvider } from "ethers";

interface IContractDeployForm {
  tokenName: string;
  tokenSymbol: string;
  contractVersion: LGTContractVersion;
}

const DeployContract = ({ template, disabled, onChange, provider, tokenName, tokenSymbol, isPoM = false } :
  { template: TTemplate, disabled: boolean, onChange: () => Promise<any>, provider?: JsonRpcProvider, tokenName?: string, tokenSymbol?: string, isPoM?: boolean }) => {

    const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IContractDeployForm>({
    shouldUseNativeValidation: true,
    values: {
      tokenName: tokenName || template?.name?.replaceAll(/\s/g, '').slice(0, 25) || '',
      tokenSymbol: tokenSymbol || template?.name?.replaceAll(/\s/g, '').slice(0, 15) || '',
      contractVersion: (isPoM ? template?.secondary_contract_version : template?.contract_version) || 0,
    },
  });

  const [isDeploying, setIsDeploying] = useState(false);
  const [txHash, setTxHash] = useState<string | undefined>(undefined);
  const { userAuth } = useContext(AuthContext);

  const handleContractDeploy = async (data: IContractDeployForm) => {
    if (!userAuth?.token) {
      return;
    }

    setIsDeploying(true);

    const { tokenName, tokenSymbol, contractVersion } = data;

    const tx = await deployContract(template.id, tokenName, tokenSymbol, userAuth?.token, template.nft_chain_id as NETWORK,
      isPoM ? LGTContractVersion.ERC1155_NFT_DISTRIBUTION : contractVersion);

    setTxHash(tx.hash);
    await provider?.waitForTransaction(tx.hash)
    setTxHash(undefined);

    await onChange();
    setIsDeploying(false);
  }

  const contractAddress = isPoM ? template?.secondary_nft_contract_address : template?.nft_contract_address;

  return (
    <form onSubmit={handleSubmit(handleContractDeploy)}>
      <Stack spacing={2}>
        {isPoM && <TextField type="number"
          {...register("contractVersion", { required: true })}
          select
          label="Contract Type"
          required
          disabled={disabled}
          helperText="ERC1155 token IDs can be traced back to the primary NFT (LGT Item #1 -> ERC1155 #1). ERC721 token IDs are sequential across all items in the SKU (#1, #2, etc)."
        >
          <MenuItem value={LGTContractVersion.ERC1155_NFT_DISTRIBUTION}>
            ERC1155 (Token IDs minted are the same as the token IDs of the LGT items)
          </MenuItem>
          <MenuItem value={LGTContractVersion.ERC721_NFT_DISTRIBUTION}>
            ERC721 (Token IDs minted are sequential across all items in the SKU)
          </MenuItem>
        </TextField>}
        <Stack spacing={2} direction="row">
          <TextField type="text"
            {...register("tokenName", { required: true })}
            label="NFT Token Name"
            required
            autoComplete="off"
            disabled={disabled}
            error={!!errors.tokenName}
            inputProps={{ maxLength: 25 }}
            helperText="This is the token name for the NFT. 25 characters max, no spaces."
          />
          {!isPoM && <TextField type="text"
            {...register("tokenSymbol", { required: true })}
            error={!!errors.tokenSymbol}
            label="NFT Token Symbol"
            required
            autoComplete="off"
            disabled={disabled}
            inputProps={{ maxLength: 15 }}
            helperText="This is the symbol of the NFT contract. 15 characters max, no spaces."
          />}
        </Stack>
        {txHash && <Alert severity="info">
          Waiting for transaction <Link href={generateTxLink(template.nft_chain_id as NETWORK, txHash)} target="_blank">{txHash}</Link> on {NETWORK_NAME_BY_CHAIN_ID[template.nft_chain_id as NETWORK]}.
        </Alert>}
        {contractAddress && <Alert severity="success">
          Contract successfully deployed to <Link href={generateAddressLink(template.nft_chain_id as NETWORK, contractAddress)} target="_blank">{contractAddress}</Link> on {NETWORK_NAME_BY_CHAIN_ID[template.nft_chain_id as NETWORK]} and verified.
        </Alert>}
        <Button type="submit"
          variant="contained"
          disabled={!!contractAddress || !template.nft_chain_id || disabled || isDeploying}
          startIcon={isDeploying && <CircularProgress size={16} />}
        >
          {!template.nft_chain_id && 'Select NFT Blockchain to Deploy Contract'}
          {template.nft_chain_id && `Deploy Contract to ${NETWORK_NAME_BY_CHAIN_ID[template.nft_chain_id as NETWORK]}`}
        </Button>
      </Stack>
    </form>
  );
}

export default DeployContract;
