import { Box, Button, Container, MenuItem, Select, Typography } from "@mui/material"
import { useContext, useState } from "react"
import { AuthContext } from "../utils/auth/useAuth";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { TTag, getTags } from "../utils/network/tags";
import { useQuery } from "@tanstack/react-query";
import { getTemplates } from "../utils/network/templates";
import { useSearchParams } from "react-router-dom";

const TagIndex = () => {
  const { userAuth } = useContext(AuthContext);

  let [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(searchParams);
  const skuId = params.get('sku') || '';

  const [selectedSku, setSelectedSku] = useState<string>(skuId || '');

  const { data, isLoading } = useQuery({
    queryKey: ['Tags', selectedSku],
    queryFn: () => getTags(userAuth?.token || '', 1, selectedSku ? Number(selectedSku) : undefined),
    enabled: !!userAuth,
  })

  const { data: skus, isLoading: isSkusLoading } = useQuery({
    queryKey: ['SKUs'],
    queryFn: () => getTemplates(userAuth?.token || ''),
    enabled: !!userAuth,
  })

  const columns = [
    {
      field: 'uuid',
      headerName: 'Tag ID',
      width: 150,
      type: 'string',
    },
    {
      field: 'verifications',
      headerName: 'Verifications',
      width: 100,
      type: 'number',
      renderCell: (params: GridRenderCellParams<TTag, number>) => {
        return <Button href={`/views?uuid=${params.row.uuid}&sku=${selectedSku}`}
          fullWidth
          variant="outlined"
        >
          {params.value}
        </Button>
      }
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 180,
      type: 'dateTime',
      valueGetter: (params: any) => {
        return new Date(params.row.created_at);
      }
    },
    {
      field: 'template_id',
      headerName: 'SKU ID',
      width: 60,
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'SKU Name',
      width: 250,
      type: 'string',
    },
    {
      field: 'notes',
      headerName: 'Notes',
      width: 150,
      type: 'string',
    },
    {
      field: 'nft_token_id',
      headerName: 'Serial',
      width: 100,
      type: 'number',
    },
    {
      field: 'shareable',
      headerName: 'Shareable',
      width: 80,
      type: 'boolean',
    },
    {
      field: 'qc',
      headerName: 'QC',
      width: 80,
      type: 'boolean',
    },
    {
      field: 'counter',
      headerName: 'Read Counter',
      width: 100,
      type: 'number',
    },
    {
      field: 'tamper',
      headerName: 'Tamper Status',
      width: 120,
      type: 'string',
    },
    {
      field: 'tamper_enabled',
      headerName: 'TagTamper Enabled',
      width: 150,
      type: 'boolean',
    }
  ]

  const handleTemplateSelect = (templateId: string) => {
    setSelectedSku(templateId);
    const params = new URLSearchParams(searchParams);
    params.set('sku', templateId);
    setSearchParams(params);
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h2">Tags</Typography>
      <Box sx={{ height: 20, width: '100%' }} />
      <Select
        displayEmpty
        onChange={e => handleTemplateSelect(e.target.value)}
        sx={{ width: 400 }}
        defaultValue={skuId}
        disabled={isSkusLoading}
      >
        <MenuItem key='none' value={''}>
          All SKUs
        </MenuItem>
        {skus?.map((sku) => (
          <MenuItem key={sku.id} value={sku.id}>
            {sku.id} - {sku.name}
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ height: 20, width: '100%' }} />
      <DataGrid
        getRowId={(row) => row.uuid}
        rows={data?.tags || []}
        paginationMode="server"
        onPaginationModelChange={(params) => {
          // fetchTags({ page: params.page });
        }}
        rowCount={data?.tags.length || 0}
        autoHeight
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
        loading={isLoading}
        pageSizeOptions={[100]}
        disableRowSelectionOnClick
      />
    </Container>
  )
}

export default TagIndex
