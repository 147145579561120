import { BASE_URL } from "./baseUrl";
import { NetworkRequestError } from "./error";

export enum TagVerificationType {
  VERIFICATION_TYPE_PROGRAMMING_QC = 'programming_qc',
  VERIFICATION_TYPE_MANUFACTURER_QC = 'manufacturer_qc',
  VERIFICATION_TYPE_USER_VIEW_TAP = 'user_view'
}

export type TTagVerification = {
  created_at: string;
  verify_type: TagVerificationType;
  template_id: number;
  uuid: string;
  nft_token_id?: number;
  city?: string;
  region?: string;
  country?: string;
  latitude?: number;
  longitude?: number;
  tamper?: string;
  counter: number;
}

export const fetchTagVerifications = async (
  authToken: string,
  templateId?: number,
  uuid?: string,
  serverBaseUrl: string = localStorage.getItem('baseUrl') || BASE_URL.PRODUCTION,
) => {
  try {
    const queryParams = new URLSearchParams();

    if (templateId) {
      queryParams.append('template_id', templateId.toString());
    }
    if (uuid) {
      queryParams.append('uuid', uuid);
    }

    const response = await fetch(`${serverBaseUrl}/verifications?${queryParams}`, {
      method: 'GET',
      headers: {
        Authorization: authToken,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new NetworkRequestError(await response.json(), response.status);
    }
    const responseBody: { verifications: TTagVerification[] } = await response.json();
    return responseBody;
  } catch (e) {
    throw e;
  }
};
